import { deleteCookie, getCookie, setCookie } from 'cookies-next';
import xior, { XiorResponse } from 'xior';
import errorRetry from 'xior/plugins/error-retry';
import setupTokenRefresh from 'xior/plugins/token-refresh';

import { TOKEN_REFRESH } from '@/shared/api/endpoints';

const usersApi = xior.create({
  baseURL: 'https://users.shipblox.com',
});

usersApi.interceptors.request.use(function (config) {
  const token = getCookie('jwt');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

const shouldRefresh = (response: XiorResponse) => {
  const token = getCookie('jwt');

  return Boolean(token && response?.status && response.status === 401);
};

usersApi.plugins.use(
  errorRetry({
    enableRetry: (config, error) => {
      return (
        error?.response &&
        (shouldRefresh(error.response) || error.response.status === 409)
      );
    },
  }),
);

setupTokenRefresh(usersApi, {
  shouldRefresh,
  async refreshToken(error) {
    try {
      const { data } = await usersApi.post(
        TOKEN_REFRESH,
        {},
        { credentials: 'include' },
      );
      if (data.data.jwt) {
        setCookie('jwt', data.data.jwt);
      }
    } catch (e) {
      deleteCookie('jwt');
      return Promise.reject(error);
    }
  },
});

export default usersApi;
