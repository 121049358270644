'use client';
import cn from 'clsx';
import { observer } from 'mobx-react-lite';
import { className } from 'postcss-selector-parser';
import React from 'react';

import { Typography } from '@/shared/ui';
import { DropDownWrapper } from '@/shared/ui/desktop/drop-down-wrapper/drop-down-wrapper';
import { useStore } from '@/store/context';

import styles from './drop-list-balance.module.scss';

export const DropListBalanceContent = observer(
  ({
    toggleDropDown,
    dropListOpened,
    className,
  }: {
    toggleDropDown: () => void;
    dropListOpened: boolean;
    className?: string;
  }) => {
    const appStore = useStore().app;
    const currencySign = appStore.getCurrencySign;

    return (
      <DropDownWrapper
        close={toggleDropDown}
        isOpened={dropListOpened}
        className={cn(styles.drop_list, className)}
      >
        <div className={styles['container-profile_info']}>
          <Typography weight="semi-bold" className={styles['drop-list-title']}>
            {`${currencySign} 0.00`}
          </Typography>
          <Typography weight="medium" className={styles['drop-list-subtitle']}>
            Your balance
          </Typography>
          <Typography weight="medium" className={styles['drop-list-text']}>
            The returned money is credited to your balance
          </Typography>
          <Typography weight="medium" className={styles['drop-list-text']}>
            you can use it in further purchases partially or fully paying their
            cost!
          </Typography>
        </div>
      </DropDownWrapper>
    );
  },
);
