import React from 'react';

import { Filter } from '@/shared/types/common';
import { Illustration } from '@/shared/ui';

import styles from './drop-list-sort.module.scss';

export const SORTING_FILTERS_BY_VARIANT: { [key: string]: Filter[] } = {
  shop: [
    {
      label: 'Popular First',
      filter: 'popular',
      group: 'sort',
      icon: (
        <Illustration
          spanTagClassName={styles['item-icon']}
          name={'popular'}
          style={{ color: '#FE9920' }}
          spriteName={'icons'}
        />
      ),
    },
    {
      label: 'Relevance',
      filter: 'relevance',
      group: 'sort',
      icon: (
        <Illustration
          spanTagClassName={styles['item-icon']}
          name={'relevance'}
          style={{ color: '#4891FF' }}
          spriteName={'icons'}
        />
      ),
    },
    {
      label: 'Cheaper',
      filter: 'cheaper',
      group: 'sort',
      icon: (
        <Illustration
          spanTagClassName={styles['item-icon']}
          name={'cheaper'}
          style={{ color: '#40BB18' }}
          spriteName={'icons'}
        />
      ),
    },
    {
      label: 'Expensive',
      filter: 'expensive',
      group: 'sort',
      icon: (
        <Illustration
          spanTagClassName={styles['item-icon']}
          name={'expensive'}
          style={{ color: '#E51A2E' }}
          spriteName={'icons'}
        />
      ),
    },
  ],
  'profile-history': [
    {
      label: 'Top Up',
      filter: 'top-up',
      group: 'profile-history',
      icon: (
        <Illustration
          spanTagClassName={styles['item-icon']}
          name={'wallet'}
          style={{ color: '#4891FF' }}
          spriteName={'icons'}
        />
      ),
    },
    {
      label: 'Withdraw',
      filter: 'withdraw',
      group: 'profile-history',
      icon: (
        <Illustration
          spanTagClassName={styles['item-icon']}
          name={'bill-check'}
          style={{ color: '#FE9920' }}
          spriteName={'icons'}
        />
      ),
    },
    {
      label: 'Purchases',
      filter: 'purchases',
      group: 'profile-history',
      icon: (
        <Illustration
          spanTagClassName={styles['item-icon']}
          name={'bill-list'}
          style={{ color: '#40BB18' }}
          spriteName={'icons'}
        />
      ),
    },
  ],
  favorite: [
    {
      label: 'Popular First',
      filter: 'popular',
      group: 'sort',
      icon: (
        <Illustration
          spanTagClassName={styles['item-icon']}
          name={'popular'}
          style={{ color: '#FE9920' }}
          spriteName={'icons'}
        />
      ),
    },
    {
      label: 'Available first',
      filter: 'available',
      group: 'sort',
      icon: (
        <Illustration
          spanTagClassName={styles['item-icon']}
          name={'archive-minimalistic'}
          style={{ color: '#6DA7FF' }}
          spriteName={'icons'}
        />
      ),
    },
    {
      label: 'Newer',
      filter: 'newer',
      group: 'sort',
      icon: (
        <Illustration
          spanTagClassName={styles['item-icon']}
          name={'archive-up-minimlistic'}
          style={{ color: '#66C946' }}
          spriteName={'icons'}
        />
      ),
    },
    {
      label: 'Older',
      filter: 'older',
      group: 'sort',
      icon: (
        <Illustration
          spanTagClassName={styles['item-icon']}
          name={'archive-down'}
          style={{ color: '#EF6363' }}
          spriteName={'icons'}
        />
      ),
    },
    {
      label: 'Cheaper',
      filter: 'cheaper',
      group: 'sort',
      icon: (
        <Illustration
          spanTagClassName={styles['item-icon']}
          name={'cheaper'}
          style={{ color: '#40BB18' }}
          spriteName={'icons'}
        />
      ),
    },
    {
      label: 'Expensive',
      filter: 'expensive',
      group: 'sort',
      icon: (
        <Illustration
          spanTagClassName={styles['item-icon']}
          name={'expensive'}
          style={{ color: '#E51A2E' }}
          spriteName={'icons'}
        />
      ),
    },
  ],
};
