import { RecoveryData } from '@features/auth-modal-content/ui/recovery-form/types';
import { SignInData } from '@features/auth-modal-content/ui/sign-in-form/types';
import { SignUpData } from '@features/auth-modal-content/ui/sign-up-form/types';

import {
  LOG_OUT,
  RECOVERY_PASSWORD_REQUEST,
  SIGN_IN,
  SIGN_UP,
  USER_INFO,
} from '@/shared/api/endpoints';
import usersApi from '@/shared/api/usersApi';
import { AuthResponse, ProfileResponse } from '@/store/authorization/types';

export const signIn = async (data: SignInData) =>
  await usersApi.post<AuthResponse>(SIGN_IN, data, { credentials: 'include' });

export const signUp = async (data: SignUpData) =>
  await usersApi.post<AuthResponse>(SIGN_UP, data, { credentials: 'include' });

export const recoveryPasswordRequest = async (data: RecoveryData) =>
  await usersApi.post(RECOVERY_PASSWORD_REQUEST, data);

export const getUserData = async () => {
  const response = await usersApi.get<ProfileResponse>(USER_INFO);
  if (response.status == 200) return response.data.data;
  return null;
};
export const logOut = async () =>
  await usersApi.post(LOG_OUT, { credentials: 'include' });
