import { useTimer } from '@hooks/client';
import { useDevice } from '@hooks/server';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useMediaQuery } from 'usehooks-ts';

import { CardSellerInfoEntity, CardSellersWrapper } from '@/entities';
import { CircleTimer, Description, ModalContent, TagInfo } from '@/shared/ui';
import { AdditionalInfo } from '@/shared/ui/desktop/additional-info';
import { AdditionalInfoMobile } from '@/shared/ui/mobile/additional-info';
import { useStore } from '@/store/context';

import styles from './waiting.module.scss';

export const WaitingSellerAccountFeature = observer(() => {
  const withdrawal = useStore()?.withdrawal;
  const isDesktop = useMediaQuery('(min-width: 1024px)');
  const {
    timer,
    count,
    // eslint-disable-next-line react-hooks/rules-of-hooks
  } = useTimer({
    duration: 20,
  });

  useEffect(() => {
    if (count === 0) {
      withdrawal?.setNextStep('pickUpItems');
    }
  }, [count]);

  return (
    <ModalContent
      className={styles['container']}
      topRightSlot={
        <ModalContent.CloseButton onClick={() => withdrawal?.close()} />
      }
    >
      <ModalContent.Title title={'Waiting'} subtitle={"Seller's account"} />

      <Description
        message={
          "The seller's account is starting up and preparing to issue items."
        }
      />
      <ModalContent.Body>
        <div className={styles['timer-container']}>
          <CircleTimer time={timer.slice(3)} />
        </div>
      </ModalContent.Body>

      <ModalContent.Footer>
        {isDesktop ? (
          <AdditionalInfo
            align={'center'}
            width={'full-w'}
            variant={'success'}
            icon={
              <AdditionalInfo.DefaultIcon variant={'success'} name={'clock'} />
            }
          >
            The wait may take: 2-16 minute
          </AdditionalInfo>
        ) : (
          <AdditionalInfoMobile
            width={'full-w'}
            variant={'success'}
            icon={
              <AdditionalInfoMobile.DefaultIcon
                variant={'success'}
                name={'clock'}
              />
            }
          >
            The wait may take: 2-16 minute
          </AdditionalInfoMobile>
        )}

        <CardSellersWrapper>
          <CardSellerInfoEntity.NavigationButton direction={'prev'} />

          <CardSellerInfoEntity
            src={''}
            playerInfo={{ name: 'Roberto' }}
            bottomSlot={
              <CardSellerInfoEntity.StatusWrapper>
                <TagInfo variant={'status'}>Waiting</TagInfo>
              </CardSellerInfoEntity.StatusWrapper>
            }
          />

          <CardSellerInfoEntity.NavigationButton direction={'next'} />
        </CardSellersWrapper>
      </ModalContent.Footer>
    </ModalContent>
  );
});
