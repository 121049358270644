import { useTimer } from '@hooks/client';
import { observer } from 'mobx-react-lite';
import React from 'react';

import {
  CardSellerInfoEntity,
  CardSellerItemEntity,
  CardSellerItemsWrapper,
  CardSellersWrapper,
} from '@/entities';
import {
  Description,
  IllustrationModal,
  ModalContent,
  TagInfo,
} from '@/shared/ui';
import { useStore } from '@/store/context';

import styles from './pickup-items.module.scss';

export const PickUpItemsFeature = observer(() => {
  const withdrawal = useStore()?.withdrawal;
  const selectedIds = useStore()?.withdrawal.selectedIds;
  const cards = useStore()?.withdrawal.cards;
  const selectedInventoryItems = cards.filter(item =>
    selectedIds?.includes(item.id),
  );

  const {
    timer,
    count,
    // eslint-disable-next-line react-hooks/rules-of-hooks
  } = useTimer({
    duration: 300,
  });

  return (
    <ModalContent
      className={styles['container']}
      topRightSlot={
        <ModalContent.CloseButton onClick={() => withdrawal?.close()} />
      }
    >
      <ModalContent.Title
        className={styles['title']}
        title={'Pick up items'}
        subtitle={'Item withdrawal'}
      />
      <ModalContent.Body className={styles['body']}>
        <IllustrationModal variant={'pick-up-items'} />
        <Description
          className={styles['description']}
          message={
            'Go to the bot\'s server and click "teleport to the bot" and take items from it.'
          }
        />
        {Array.isArray(selectedInventoryItems) &&
          selectedInventoryItems?.length > 0 && (
            <CardSellerItemsWrapper>
              {selectedInventoryItems?.map(item => {
                return (
                  <CardSellerItemEntity
                    tagCategory={item?.tagCategory ? item?.tagCategory : []}
                    key={`CardSellerItemEntity-${item.id}`}
                    rarity={item.rarity}
                    item={item.item}
                  />
                );
              })}
            </CardSellerItemsWrapper>
          )}
      </ModalContent.Body>

      <ModalContent.Footer>
        <ModalContent.Button
          variant={'primary'}
          text={'Go to server'}
          onClick={() => {
            withdrawal?.setNextStep('withdrawalSuccess');
          }}
        />
        <CardSellersWrapper>
          <CardSellerInfoEntity.NavigationButton direction={'prev'} />

          <CardSellerInfoEntity
            src={''}
            playerInfo={{ name: 'Roberto' }}
            bottomSlot={
              <CardSellerInfoEntity.StatusWrapper>
                <TagInfo variant={'status'}>Meeting</TagInfo>
                <TagInfo variant={'timer'}>{timer.slice(3)}</TagInfo>
              </CardSellerInfoEntity.StatusWrapper>
            }
          />

          <CardSellerInfoEntity.NavigationButton direction={'next'} />
        </CardSellersWrapper>
      </ModalContent.Footer>
    </ModalContent>
  );
});
