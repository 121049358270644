import { Filter } from '@/shared/types/common';

export const SIDEBAR_CATEGORIES_FILTERS: {
  topSlot: {
    mm2: Filter[];
    adopt: Filter[];
  };
  bottomSlot: Filter[];
} = {
  topSlot: {
    adopt: [
      {
        label: 'Pets',
        group: 'category',
        icon: 'cat',
        filter: 'pets',
        color: '#FE9920',
      },
      {
        label: 'Eggs',
        icon: 'egg',
        group: 'category',
        filter: 'eggs',
        color: '#D60F86',
      },
      {
        label: 'Potions',
        icon: 'potion',
        group: 'category',
        filter: 'potions',
        color: '#4891FF',
      },
      {
        label: 'Transport',
        icon: 'bus',
        group: 'category',
        filter: 'transport',
        color: '#6941C6',
      },
      {
        label: 'All',
        group: 'category',
        icon: 'widget',
        filter: 'all',
        color: '#6941C6',
      },
    ],
    mm2: [
      {
        label: 'Knives',
        group: 'category',
        icon: 'knife',
        filter: 'knives',
        color: '#4891FF',
      },
      {
        label: 'Guns',
        icon: 'gun',
        group: 'category',
        filter: 'guns',
        color: '#40BB18',
      },
      {
        label: 'Pets',
        icon: 'cat',
        group: 'category',
        filter: 'pets',
        color: '#FE9920',
      },
      {
        label: 'Bundles',
        icon: 'box',
        group: 'category',
        filter: 'bundles',
        color: '#6941C6',
      },
      {
        label: 'All',
        group: 'category',
        icon: 'widget',
        filter: 'all',
        color: '#6941C6',
      },
    ],
  },
  bottomSlot: [
    {
      label: 'New',
      icon: 'lightning',
      group: 'category',
      filter: 'new',
      color: '#40BB18',
    },
    {
      label: 'Exclusive',
      icon: 'star',
      group: 'category',
      filter: 'exclusive',
      color: '#303030',
    },
    {
      label: 'Discounted Items',
      icon: 'ticket-sale',
      filter: 'discount',
      group: 'category',
      color: '#FE9920',
    },
    {
      label: 'Items Under $ 3.5',
      icon: 'tag',
      filter: 'cheap',
      group: 'category',
      color: '#4891FF',
    },
    /*    {
          label: 'X-MAS Sale',
          icon: 'cup-hot',
          filter: 'x-mas',
          group: 'category',
          color: '#EB3C3C',
        },*/
  ],
};

export const TRANSPORT_FILTERS: Filter[] = [
  { label: 'Scooters', filter: 'scooters', group: 'category' },
  { label: 'Skateboards', filter: 'skateboards', group: 'category' },
  { label: 'Snowboard', filter: 'snowboard', group: 'category' },
  {
    label: 'Legendary Gift Vehicle',
    filter: 'legendary-gift-vehicle',
    group: 'category',
  },
  { label: 'Event Vehicle', filter: 'event-vehicle', group: 'category' },
  {
    label: 'Vehicle Dealership',
    filter: 'vehicle-dealership',
    group: 'category',
  },
  { label: 'Premium Vehicle', filter: 'premium-vehicle', group: 'category' },
  {
    label: 'Star Reward Vehicle',
    filter: 'star-reward-vehicle',
    group: 'category',
  },
  {
    label: 'Miscellaneous Vehicle',
    filter: 'miscellaneous-vehicle',
    group: 'category',
  },
];
