export const SIGN_IN = '/api/auth/login';
export const SIGN_UP = '/api/auth/signup';
export const RECOVERY_PASSWORD_REQUEST = '/api/auth/forgot-password';

export const VERIFY_EMAIL = '/api/auth/send-verify-email';

export const CHANGE_PASSWORD = '/api/profile/change-password';

export const USER_INFO = '/api/profile';

export const SOCIAL_AUTH = (provider: string) => `/api/auth/login/${provider}`;
export const VERIFY_SOCIAL_AUTH = (provider: string, query: string) =>
  `/api/auth/login/verify/${provider}?${query}`;

export const TOKEN_REFRESH = '/api/auth/refresh';

export const LOG_OUT = '/api/auth/logout';
