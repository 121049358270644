'use client';
import { CardItemShopEntityMobile } from '@entities/mobile/cards';
import { AddToCartFeatureMobile } from '@features/mobile/add-to-cart';
import { AddToFavoriteFeatureMobile } from '@features/mobile/add-to-favorite';
import { SelectPetPropertiesFeature } from '@features/select-pet-properties';
import { ContentWidgetItemsBlockProps } from '@widgets/desktop/content/ui/content';
import { useRouter } from 'next/navigation';
import React from 'react';

import { SeeAllButton } from '@/shared/components/see-all-button';
import { WithShopItems } from '@/shared/hocs';
import { Label } from '@/shared/ui';
import { CartItemType } from '@/store/cart/cart';

import styles from './list-cards-wrappers.module.scss';

export const PopularItems = () => {
  return (
    <WithShopItems>
      {({ items }) => {
        return (
          <ItemsBlock
            items={items}
            headerSlot={
              <>
                <Label
                  labelVariant={'yellow'}
                  icon={'graph-up'}
                  variant={'primary'}
                >
                  Popular items
                </Label>
                <SeeAllButton
                  href={'/shop?sort=popular'}
                  variant={'secondary'}
                />
              </>
            }
          />
        );
      }}
    </WithShopItems>
  );
};
export const CloseInValueItems = () => {
  return (
    <WithShopItems>
      {({ items }) => {
        return (
          <ItemsBlock
            items={items}
            headerSlot={
              <>
                <Label
                  labelVariant={'yellow'}
                  icon={'graph-up'}
                  variant={'primary'}
                >
                  Items close in value
                </Label>
              </>
            }
          />
        );
      }}
    </WithShopItems>
  );
};

export const ExclusiveItems = () => {
  return (
    <WithShopItems>
      {({ items }) => {
        return (
          <ItemsBlock
            items={items}
            headerSlot={
              <>
                <Label
                  labelVariant={'black'}
                  icon={'star-circle'}
                  variant={'primary'}
                >
                  Exclusive Items
                </Label>
                <SeeAllButton
                  href={'/shop?sort=popular'}
                  variant={'secondary'}
                />
              </>
            }
          />
        );
      }}
    </WithShopItems>
  );
};
export const HotSalesItems = () => {
  return (
    <WithShopItems>
      {({ items }) => {
        return (
          <ItemsBlock
            items={items}
            headerSlot={
              <>
                <Label
                  labelVariant={'red'}
                  icon={'fire-square-red'}
                  variant={'primary'}
                >
                  Hot Sales
                </Label>
                <SeeAllButton
                  href={'/shop?sort=popular'}
                  variant={'secondary'}
                />
              </>
            }
          />
        );
      }}
    </WithShopItems>
  );
};

export const ItemsBlock = ({
  headerSlot,
  items,
}: ContentWidgetItemsBlockProps) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const router = useRouter();

  return (
    <div className={styles['content-widget__items-block']}>
      {headerSlot && <div className={styles['header']}>{headerSlot}</div>}
      <div className={styles['items']}>
        {items?.map((item, idx) => {
          let itemReadyToCart: CartItemType = {
            ...item,
            status: 'available',
            quantity: 1,
          };

          return (
            <CardItemShopEntityMobile
              onClick={() => router.push(`/shop/${item.category}/${item?.id}`)}
              key={`card-${idx}-${item.info.title}-${item?.info?.price?.old}`}
              item={item.item}
              rarity={item.rarity}
              info={{
                title: item?.info?.title,
                price: {
                  old: item?.info?.price?.old,
                  current: item?.info?.price?.current,
                },
              }}
              propertiesSlot={<SelectPetPropertiesFeature item={item} />}
              tagsInfo={item?.tagsInfo}
              topRightSlot={
                <AddToFavoriteFeatureMobile variant={'primary'} id={item.id} />
              }
              bottomSlot={<AddToCartFeatureMobile item={itemReadyToCart} />}
            />
          );
        })}
      </div>
    </div>
  );
};
