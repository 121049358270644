import { deleteCookie } from 'cookies-next';
import { usePathname, useRouter } from 'next/navigation';
import { useMutation, useQueryClient } from 'react-query';

import { logOut } from '@/shared/api/user/queries';
import { isRouteProtected } from '@/shared/constants/routes';

export const useLogout = () => {
  const path = usePathname();
  const router = useRouter();
  const queryClient = useQueryClient();

  return useMutation(logOut, {
    onSuccess: () => {
      deleteCookie('jwt');
      queryClient.setQueryData(['user'], null);
      if (isRouteProtected(path)) {
        router.replace('/');
      }
    },
  });
};
