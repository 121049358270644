'use client';
import { SidebarFavoritesEntity } from '@entities/sidebar';
import {
  ContentWrapperProps,
  HistoryWidgetHeaderProps,
  HistoryWidgetTableBodyProps,
  HistoryWidgetTableHeaderProps,
  HistoryWidgetTableProps,
  HistoryWidgetTableRowProps,
  TableDate,
  TableId,
  TableItem,
  TableMethod,
  TableNickname,
  TablePrice,
  TableProperties,
  TablePurchasesRows,
  TableStatus,
  TableTopUpRows,
  TableTotal,
  TableType,
  TableWithdrawRows,
} from '@widgets/desktop/profile-history/ui/profile-history.types';
import cn from 'clsx';
import { observer } from 'mobx-react-lite';
import { usePathname, useRouter } from 'next/navigation';
import React, { ComponentProps, ReactNode, Suspense, useMemo } from 'react';
import { Virtuoso } from 'react-virtuoso';

import { testData } from '@/mocks/profile-history-tables';
import { DivElementType } from '@/shared/types/common';
import {
  Divider,
  Illustration,
  Item,
  ItemRarityLiveFeed,
  TagCategory,
  Typography,
} from '@/shared/ui';
import { InputAuth } from '@/shared/ui/desktop/input-auth';
import { Tabs } from '@/shared/ui/tabs';
import { useStore } from '@/store/context';

import styles from './profile-history.module.scss';

export const ProfileHistoryWidget = () => {
  return (
    <ProfileHistoryWidget.ContentWrapper>
      <ProfileHistoryWidget.Header />
      <ProfileHistoryWidget.Table />
    </ProfileHistoryWidget.ContentWrapper>
  );
};

// eslint-disable-next-line react/display-name
ProfileHistoryWidget.ContentWrapper = ({
  children,
  className,
  ...props
}: ContentWrapperProps) => {
  return (
    <div className={cn(styles['content'], className)} {...props}>
      {children}
    </div>
  );
};

// eslint-disable-next-line react/display-name
ProfileHistoryWidget.Header = ({
  className,
  ...props
}: HistoryWidgetHeaderProps) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const router = useRouter();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const pathname = usePathname();
  const slugs = pathname.split('/');

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return (
    <div className={cn(styles['content__header'], className)} {...props}>
      <div className={styles['input_container']}>
        <InputAuth
          withErrorMessage={false}
          size={'s'}
          iconName={'magnifer'}
          value=""
          onChange={() => {}}
          placeholder="Date, amount, items..."
        />
      </div>

      <Tabs<'withdraw' | 'purchases' | 'top-up'>
        className={styles['tabs-container']}
        defaultActiveTab={
          slugs[slugs.length - 1] as 'withdraw' | 'purchases' | 'top-up'
        }
        onTabsChange={tab => router.push(`/profile/history/${tab}`)}
      >
        <Tabs.ItemFilter value={'top-up'}>Top-up</Tabs.ItemFilter>
        <Tabs.ItemFilter value={'withdraw'}>Withdraw</Tabs.ItemFilter>
        <Tabs.ItemFilter value={'purchases'}>Purchases</Tabs.ItemFilter>
      </Tabs>
    </div>
  );
};

// eslint-disable-next-line react/display-name
ProfileHistoryWidget.Table = ({
  className,
  children,
  ...props
}: HistoryWidgetTableProps) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const pathname = usePathname();
  const slugs = pathname.split('/');
  const currentSlug = slugs[slugs.length - 1];

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const rows = useMemo(() => {
    if (typeof window !== 'undefined' && window?.emptyHistoryScreen) {
      return testData[currentSlug as keyof typeof testData].rows.slice(0, 0);
    } else {
      return testData[currentSlug as keyof typeof testData].rows;
    }
  }, []);

  return (
    <Suspense fallback={<></>}>
      <div className={cn(styles['content__table'], className)} {...props}>
        <ProfileHistoryWidget.TableHeader />
        <ProfileHistoryWidget.TableBody>
          {Array.isArray(rows) && rows.length > 0 ? (
            <Virtuoso<TablePurchasesRows | TableTopUpRows | TableWithdrawRows>
              className={cn(styles['content__table-body'], className)}
              data={rows}
              itemContent={(idx, row) => (
                <React.Fragment key={`${row.id}-${idx}`}>
                  <ProfileHistoryWidget.TableRow row={row} />
                  {idx !== rows.length - 1 && (
                    <Divider
                      style={{ minHeight: 1 }}
                      direction={'horizontal'}
                    />
                  )}
                </React.Fragment>
              )}
            />
          ) : (
            <ProfileHistoryWidget.Empty />
          )}
        </ProfileHistoryWidget.TableBody>
      </div>
    </Suspense>
  );
};

// eslint-disable-next-line react/display-name
ProfileHistoryWidget.Empty = observer(() => {
  const pathname = usePathname();
  const slugs = pathname.split('/');
  const currentSlug = slugs[slugs.length - 1];

  const subtitleBySlug = {
    'top-up': 'Your deposit history will appear here',

    withdraw:
      'Your withdrawal history will appear here after you claim your first item',

    purchases:
      'Your purchase history will appear here after you sell your first item',
  };

  return (
    <SidebarFavoritesEntity
      id={'profile-history-empty'}
      className={cn(styles['content__table-body-empty'])}
      topSlot={<SidebarFavoritesEntity.Image variant={'history-no-items'} />}
      middleSlot={
        <SidebarFavoritesEntity.Info
          className={styles['empty-info']}
          info={{
            title: "There's nothing here yet",
            subtitle:
              subtitleBySlug[currentSlug as keyof typeof subtitleBySlug],
          }}
        />
      }
    />
  );
});

// eslint-disable-next-line react/display-name
ProfileHistoryWidget.TableHeader = ({
  className,
  ...props
}: HistoryWidgetTableHeaderProps) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const pathname = usePathname();
  const slugs = pathname.split('/');
  const currentSlug = slugs[slugs.length - 1];

  const cols = testData[currentSlug as keyof typeof testData].columns;
  const colsTitles = Object.values(cols);

  return (
    <div className={cn(styles['content__table-header'], className)} {...props}>
      {colsTitles.map((item, idx) => {
        return (
          <Typography className={styles['title']} key={`${item}-${idx}`}>
            {item.title}
          </Typography>
        );
      })}
    </div>
  );
};

// eslint-disable-next-line react/display-name
ProfileHistoryWidget.TableBody = ({
  className,
  children,
  ...props
}: HistoryWidgetTableBodyProps) => {
  return (
    <div
      className={cn(styles['content__table-body-wrapper'], className)}
      {...props}
    >
      {/* <div className={cn(styles['content__table-body'], className)}>*/}
      {children}
      {/*  </div>*/}
    </div>
  );
};

// eslint-disable-next-line react/display-name
ProfileHistoryWidget.TableRow = ({
  className,
  children,
  row,
  ...props
}: HistoryWidgetTableRowProps) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const pathname = usePathname();
  const slugs = pathname.split('/');
  const currentSlug = slugs[slugs.length - 1];
  const cols = Object.keys(
    testData[currentSlug as keyof typeof testData].columns,
  );

  return (
    <div className={cn(styles['content__table-row'], className)} {...props}>
      {cols.map((col, idx) => {
        const Component =
          contentItems[col as keyof typeof contentItems] ?? null;

        if (!Component) {
          throw new Error(`No component found for column ${col}`);
        }

        const data = row[col as keyof typeof row] as ComponentProps<
          (typeof contentItems)[string]
        >['itemData'];

        return <Component key={idx} itemData={data} />;
      })}
    </div>
  );
};

type ContentItemProps<T> = DivElementType & {
  itemData: T;
};

type ContentItemsComponents = {
  [key: string]: ({ itemData }: ContentItemProps<any>) => ReactNode;
};

const contentItems: ContentItemsComponents = {
  type: ({ itemData }: ContentItemProps<TableType>) => {
    return (
      <Typography
        className={cn(
          styles['content-components'],
          styles['content-components__type'],
        )}
      >
        {itemData}
      </Typography>
    );
  },
  item: ({ itemData }: ContentItemProps<TableItem>) => {
    return (
      <div className={cn(styles['content-components'])}>
        <div className={styles['item-info-wrapper']}>
          <div className={styles['item-container']}>
            <ItemRarityLiveFeed
              size={'52'}
              className={styles['item-rarity']}
              variant={itemData.rarity}
            />
            <Item
              className={styles['item']}
              size={'42'}
              variant={itemData.item}
            />
          </div>
          <div className={styles['item-titles']}>
            <Typography className={cn(styles['title'])}>
              {itemData.name}
            </Typography>

            {itemData?.age && (
              <Typography className={cn(styles['subtitle'])}>
                {itemData.age}
              </Typography>
            )}
          </div>
        </div>
      </div>
    );
  },
  properties: ({ itemData }: ContentItemProps<TableProperties>) => {
    return (
      <div className={cn(styles['content-components'])}>
        <div className={cn(styles['content-components__properties'])}>
          {itemData?.map((tag, idx) => {
            return (
              <TagCategory
                key={`profile-history-${tag}-${idx}`}
                variant={tag}
              />
            );
          })}
        </div>
      </div>
    );
  },
  price: observer(({ itemData }: ContentItemProps<TablePrice>) => {
    const currencySign = useStore().app.getCurrencySign;

    return (
      <Typography
        className={cn(
          styles['content-components'],
          styles['content-components__price'],
        )}
      >
        {currencySign} {itemData}
      </Typography>
    );
  }),
  id: ({ itemData }: ContentItemProps<TableId>) => {
    return (
      <div
        style={{ cursor: 'pointer' }}
        onClick={() => navigator.clipboard.writeText(String(itemData))}
        className={styles['content-components']}
      >
        <Typography className={cn(styles['content-components__id'])}>
          {itemData}
        </Typography>
        <Illustration
          name={'copy'}
          spanTagClassName={styles['icon']}
          spriteName={'icons'}
          style={{ marginLeft: 6, color: '#BFBFBF' }}
        />
      </div>
    );
  },
  date: ({ itemData }: ContentItemProps<TableDate>) => {
    return (
      <Typography
        className={cn(
          styles['content-components'],
          styles['content-components__date'],
        )}
      >
        {itemData}
      </Typography>
    );
  },
  nickname: ({ itemData }: ContentItemProps<TableNickname>) => {
    return (
      <Typography
        className={cn(
          styles['content-components'],
          styles['content-components__nickname'],
        )}
      >
        {itemData}
      </Typography>
    );
  },
  status: ({ itemData }: ContentItemProps<TableStatus>) => {
    const statuses = {
      0: 'danger',
      1: 'warning',
      2: 'success',
    };

    return (
      <Typography
        className={cn(
          styles['content-components'],
          styles['content-components__status'],
          styles[`content-components__status-${statuses[itemData.code]}`],
        )}
      >
        {itemData.text}
      </Typography>
    );
  },
  method: ({ itemData }: ContentItemProps<TableMethod>) => {
    return (
      <Typography
        className={cn(
          styles['content-components'],
          styles['content-components__method'],
        )}
      >
        {itemData}
      </Typography>
    );
  },
  total: observer(({ itemData }: ContentItemProps<TableTotal>) => {
    const currencySign = useStore().app.getCurrencySign;

    return (
      <Typography
        className={cn(
          styles['content-components'],
          styles['content-components__total'],
        )}
      >
        {currencySign} {itemData}
      </Typography>
    );
  }),
};
