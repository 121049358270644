import { observer } from 'mobx-react-lite';
import React from 'react';
import { useMediaQuery } from 'usehooks-ts';

import { CardSellerInfoEntity, CardSellersWrapper } from '@/entities';
import { Description, IllustrationModal, ModalContent } from '@/shared/ui';
import { AdditionalInfo } from '@/shared/ui/desktop/additional-info';
import { AdditionalInfoMobile } from '@/shared/ui/mobile/additional-info';
import { useStore } from '@/store/context';

import styles from './error.module.scss';

export const WithdrawalErrorFeature = observer(() => {
  const withdrawal = useStore()?.withdrawal;
  const isDesktop = useMediaQuery('(min-width: 1024px)');

  return (
    <ModalContent
      className={styles['container']}
      topRightSlot={
        <ModalContent.CloseButton onClick={() => withdrawal?.close()} />
      }
    >
      <IllustrationModal variant={'error'} />
      <ModalContent.Title title={'Oops!'} subtitle={'Item withdrawal'} />

      <ModalContent.Body>
        {isDesktop ? (
          <AdditionalInfo
            align={'center'}
            width={'full-w'}
            variant={'danger'}
            icon={
              <AdditionalInfo.DefaultIcon
                variant={'danger'}
                name={'info-circle'}
              />
            }
          >
            {"The seller's account has not been verified"}
          </AdditionalInfo>
        ) : (
          <AdditionalInfoMobile
            icon={
              <AdditionalInfoMobile.DefaultIcon
                variant={'danger'}
                name={'info-circle'}
              />
            }
            width={'full-w'}
            variant={'danger'}
          >
            {"The seller's account has not been verified"}
          </AdditionalInfoMobile>
        )}

        <Description
          message={
            'The purchase has been cancelled. The funds have already been returned to your balance.'
          }
        />
      </ModalContent.Body>

      <ModalContent.Footer>
        <CardSellersWrapper>
          <CardSellerInfoEntity.NavigationButton isError direction={'prev'} />

          <CardSellerInfoEntity
            src={''}
            playerInfo={{ name: 'Roberto' }}
            isError
          />

          <CardSellerInfoEntity.NavigationButton isError direction={'next'} />
        </CardSellersWrapper>
      </ModalContent.Footer>
    </ModalContent>
  );
});
