import cn from 'clsx';

import {
  Item,
  ItemRarityCard,
  TagCategory,
  TagRarity,
  Typography,
} from '@/shared/ui';

import styles from './card-item-live.module.scss';
import {
  CardItemLiveItemInfoProps,
  CardItemLiveItemPropertiesProps,
  CardItemLiveItemProps,
  CardItemLiveProps,
} from './card-item-live.types';

export const CardItemLiveEntityMobile = ({
  className,
  leftSlot,
  middleSlot,
  rightSlot,
  ...props
}: CardItemLiveProps) => {
  return (
    <article className={cn(styles['card-item-live'], className)} {...props}>
      {leftSlot}
      {middleSlot}
      {rightSlot}
    </article>
  );
};

CardItemLiveEntityMobile.Item = ({
  className,
  rarity,
  item,
  ...props
}: CardItemLiveItemProps) => {
  return (
    <div
      className={cn(
        styles['item-container'],
        styles['item-paddings'],
        className,
      )}
      {...props}
    >
      <ItemRarityCard
        className={cn(styles['item-rarity'], styles['item-paddings'])}
        size={'100%'}
        variant={rarity}
      />
      <Item className={styles['item']} size={'48'} variant={item} />
    </div>
  );
};

CardItemLiveEntityMobile.ItemInfo = ({
  title,
  age,
}: CardItemLiveItemInfoProps) => {
  return (
    <div className={styles['item-info-container']}>
      <div className={styles['info']}>
        {title && <Typography className={styles['title']}>{title}</Typography>}
        {age && <Typography className={styles['age']}>{age}</Typography>}
      </div>
    </div>
  );
};

CardItemLiveEntityMobile.ItemProperties = ({
  tagCategory,
  rarity,
  ...props
}: CardItemLiveItemPropertiesProps) => {
  return (
    <div className={styles['item-properties-container']} {...props}>
      {rarity && <TagRarity variant={rarity} className={styles['rarity']} />}
      {tagCategory?.map((tag, idx) => {
        return (
          <TagCategory
            className={styles['category']}
            key={`category-${tag}-${idx}`}
            variant={tag}
          />
        );
      })}
    </div>
  );
};
